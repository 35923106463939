import React from "react";

import { combineClasses } from "@kikoff/utils/src/string";

import { sputter } from "@util/analytics";

import appStoreBadge from "./images/app_store_badge.svg";
import playStoreBadge from "./images/play_store_badge.svg";

import styles from "./styles.module.scss";

export const AppStoreBadge = function ({
  className = "",
  variant = "primary",
}) {
  return (
    <a
      href="https://apps.apple.com/US/app/id1525159784?l=en"
      className={combineClasses(styles[`badge-link-${variant}`], className)}
      data-cy="app-store"
      target="_blank"
      onClick={() => {
        sputter("App Store Badge clicked");
      }}
      rel="noreferrer"
    >
      <img
        src={appStoreBadge.src}
        className={combineClasses(styles[`store-badge-${variant}`], className)}
        alt="Kikoff App Store Badge"
      />
    </a>
  );
};

export const PlayStoreBadge = function ({
  className = "",
  variant = "primary",
}) {
  return (
    <a
      href="https://play.google.com/store/apps/details?hl=en&id=com.kikoff"
      className={combineClasses(styles[`badge-link-${variant}`], className)}
      data-cy="play-store"
      target="_blank"
      onClick={() => {
        sputter("Play Store Badge clicked");
      }}
      rel="noreferrer"
    >
      <img
        src={playStoreBadge.src}
        className={combineClasses(styles[`store-badge-${variant}`], className)}
        alt="Kikoff Play Store Badge"
      />
    </a>
  );
};

import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { LocalizationProvider, Localized } from "@fluent/react";

import Logo from "@kikoff/components/src/v1/brand/Logo";
import ContainerButton from "@kikoff/components/src/v1/buttons/ContainerButton";
import Card from "@kikoff/components/src/v1/cards/Card";
import KLink from "@kikoff/components/src/v1/navigation/KLink";
import { cls, combineClasses } from "@kikoff/utils/src/string";

import McMenu from "@component/animations/mc_menu";
import PageAlign from "@component/landing/page_align";
import LandingMenu from "@component/layout/menu";
import { bpoContactNumer } from "@src/constants";
import { useBackendExperiment } from "@src/experiments/context";
import { l10nResources } from "@util/l10n";

import styles from "./styles.module.scss";

const l10n = l10nResources({
  en: `nav-build-credit = Build Credit
nav-credit-account = Credit Account
nav-disputes = Disputes
nav-cash-card = Secured Credit Card
nav-faqs = FAQs
nav-pricing = Pricing
nav-about-us = About Us
nav-rent-reporting = Rent Reporting
nav-login = Login
nav-signup = Sign up
nav-demo = Try the demo
nav-phone = 1-844-741-9292
`,
  es: `nav-build-credit = Construye Crédito
nav-credit-account = Cuenta de crédito
nav-disputes = Disputas
nav-cash-card = Tarjeta de crédito asegurada Kikoff
nav-rent-reporting = Informes de alquiler
nav-faqs = Preguntas Frecuentes
nav-pricing = Precios
nav-about-us = Sobre Nosotros
nav-login = Iniciar sesión
nav-signup = Regístrate
nav-demo = Prueba el demo
nav-phone = 1-844-741-9292
`,
});

const menuItemToLocalizedMenuItem = ([id, link]) => [l10n.getString(id), link];

const defaultMenuItems = [
  ["nav-cash-card", "/kikoff-card"],
  ["nav-faqs", "/faq"],
  ["nav-pricing", "/pricing"],
  ["nav-about-us", "/about"],
];

interface LandingNavProps {
  className?: string;
  hidden?: boolean;
}

const LandingNav: React.FC<LandingNavProps> = ({
  className,
  hidden = false,
}) => {
  const [collapse, setCollapse] = useState(false);
  const [open, setOpen] = useState(false);
  const [dropdownExpanded, setDropdownExpanded] = useState(false);
  const showHelp = useBackendExperiment("preLoginHelp") === "show_help";

  const showDropdown = dropdownExpanded && !hidden && !collapse;

  let dropdownMenuItems = [
    ["nav-credit-account", "/credit-account"],
    ["nav-rent-reporting", "/rent-reporting"],
  ];

  dropdownMenuItems = dropdownMenuItems.map(menuItemToLocalizedMenuItem);

  const menuItems = [
    ...defaultMenuItems,
    showHelp && ["nav-phone", `tel:+${bpoContactNumer}`],
  ]
    .filter(Boolean)
    .map(menuItemToLocalizedMenuItem);
  const flatMenuItems = [...dropdownMenuItems, ...menuItems];

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = ref.current;
    const padding = +getComputedStyle(container)
      .getPropertyValue("--padding")
      .slice(0, -2);

    function resize() {
      setCollapse(window.innerWidth < container.scrollWidth + padding * 2);
    }

    resize();

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  useEffect(() => {
    function click(e) {
      if (e.target !== document.querySelector(`.${styles.menu}`))
        setOpen(false);
    }
    if (open) {
      document.addEventListener("click", click);

      return () => {
        document.removeEventListener("click", click);
      };
    }
  }, [open]);

  return (
    <nav
      className={combineClasses(
        styles["landing-nav"],
        className,
        collapse && styles.collapse
      )}
    >
      <LocalizationProvider l10n={l10n}>
        <PageAlign>
          <div className={styles.container} ref={ref}>
            <header>
              <Logo size={24} variant="black" />
            </header>
            <div className={styles["navbar"]}>
              <div className={styles["links"]}>
                {dropdownMenuItems.length > 0 && (
                  <div className={styles.dropdown}>
                    <ContainerButton
                      className={styles.button}
                      onClick={() => {
                        setDropdownExpanded(!dropdownExpanded);
                      }}
                    >
                      <div>{l10n.getString("nav-build-credit")}</div>
                      <div
                        className={cls(
                          styles.arrow,
                          showDropdown && styles.expanded
                        )}
                      >
                        
                      </div>
                    </ContainerButton>
                    <Card
                      className={cls(
                        styles.content,
                        showDropdown && styles.expanded
                      )}
                      raised
                    >
                      {dropdownMenuItems.map(([label, path]) => (
                        <KLink
                          key={label}
                          className={styles.item}
                          href={path}
                          onClick={() => {
                            setDropdownExpanded(false);
                          }}
                          newLine
                        >
                          {label}
                        </KLink>
                      ))}
                    </Card>
                  </div>
                )}
                {menuItems.map(([label, path]) => (
                  <Link href={path} key={label}>
                    <a className={styles.item}>{label}</a>
                  </Link>
                ))}
              </div>
              <div className={styles["right-side"]}>
                <Link href="/login">
                  <a className={styles["inverted-link"]}>
                    <Localized id="nav-login">Login</Localized>
                  </a>
                </Link>

                <Link href="/onboarding">
                  <a className={styles["signup"]} data-cy="nav-menu-signup">
                    <Localized id="nav-signup">Sign up</Localized>
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div>
            {collapse && (
              <div
                className={styles["menu-toggle-container"]}
                data-cy="open-mobile-menu"
              >
                <McMenu
                  className={styles["menu-toggle"]}
                  isOpen={open}
                  onClick={() => {
                    setOpen(!open);
                  }}
                />
              </div>
            )}
          </div>
          <div
            className={combineClasses(
              styles["menu-background"],
              open && collapse && styles.open
            )}
          >
            <LandingMenu className={styles.menu} menuItems={flatMenuItems} />
          </div>
        </PageAlign>
      </LocalizationProvider>
    </nav>
  );
};

export default LandingNav;
